const _ = require('lodash')

const requirePromise = (requireFn, packageName) => new Promise(resolve => {
    requireFn(packageName, resolve)
})

const fetchFallbackJson = (fetchFn, fallbackUrls) => new Promise((resolve, reject) => {
    let fallbackCount = 0

    const tryToFetch = error => {
        if (fallbackCount >= fallbackUrls.length) {
            reject(error)
            return
        }

        const fallbackUrl = fallbackUrls[fallbackCount++]
        fetchFn(fallbackUrl, null, 'json', resolve, tryToFetch)
    }

    tryToFetch()
})

const fetchPageJson = ({fetchFn, requireFn, getDataFixerParams, fixedPageUrl, onSuccess, onError, fallbackUrls = []}) => {
    const fetchFromFallback = async () => {
        try {
            const [pageJson, dataFixer] = await Promise.all([
                fetchFallbackJson(fetchFn, fallbackUrls),
                requirePromise(requireFn, 'santa-data-fixer')
            ])

            onSuccess(dataFixer.fix({
                ...getDataFixerParams(),
                pageId: _.get(pageJson, ['structure', 'id'], 'masterPage'),
                pageJson
            }))
        } catch (err) {
            if (onError) {
                onError(err)
                return
            }
            throw err
        }
    }

    fetchFn(fixedPageUrl, null, 'json', onSuccess, fetchFromFallback)
}

module.exports = {
    loadPage(setPageToLoad, pageId, requestInfo) {
        setPageToLoad(pageId, requestInfo)
    },
    fetchPageJson,
    pageRequestSuccess(setPageData, successCallback, data) {
        setPageData(data)
        if (successCallback) {
            successCallback(data)
        }
    }
}
