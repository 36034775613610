const _ = require('lodash')
const {resolveUrl} = require('./scriptUrlResolver')

const isValidOverrideUrl = (url, scriptsLocation) =>
    _.startsWith(url, scriptsLocation) ||
    _.startsWith(url, 'http://localhost') ||
    _.startsWith(url, 'https://localhost')

const getOverrideViewerScriptUrl = (query, appDefinitionId, scriptsLocation) => {
    if (!query) { return null }
    const viewerScriptUrlOverride = _.get(query, 'viewerPlatformOverrides')
    if (!viewerScriptUrlOverride) { return null}
    const allViewerScriptsOverrides = viewerScriptUrlOverride.split(',')
    const viewerSctiptOverrideMap = _(allViewerScriptsOverrides)
        .invokeMap('split', '=')
        .fromPairs()
        .value()
    const viewerScriptOverrideUrl = viewerSctiptOverrideMap[appDefinitionId]
    if (isValidOverrideUrl(viewerScriptOverrideUrl, scriptsLocation)) {
        return viewerScriptOverrideUrl
    }
    return null
}

module.exports = {
    getOverrideViewerScriptUrl,
    resolveUrl
}
